<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="批次名称" prop="batchName" >-->
<!--        <a-input v-model="form.batchName" placeholder="请输入批次名称" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="批次id" prop="batchId" >
<!--        <a-input v-model="form.batchId" placeholder="请输入批次id" />-->
        <a-select
          placeholder="-批次id-"
          style="width: 100%"
          showSearch
          v-model="form.batchId"
          optionFilterProp="title"
          @change="changeBatchId"
        >
          <a-select-option v-for="item in batchListData" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>


<!--      <a-form-model-item label="状态:0-未激活 1-激活 2-禁用 3-异常 4-空的" prop="status" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="激活时间" prop="activationTime" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.activationTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="激活时间戳" prop="activationTimeStamp" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.activationTimeStamp" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="激活地址" prop="activationAddress" >-->
<!--        <a-input v-model="form.activationAddress" placeholder="请输入激活地址" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="激活经度" prop="lng" >-->
<!--        <a-input v-model="form.lng" placeholder="请输入激活经度" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="激活维度" prop="lat" >-->
<!--        <a-input v-model="form.lat" placeholder="请输入激活维度" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="设备id" prop="deviceId" >
        <a-input v-model="form.deviceId" placeholder="请输入设备id" />
      </a-form-model-item>

      <a-form-model-item label="分组" prop="deviceId" >
        <a-input v-model="form.group" placeholder="请输入分组" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBatchRecord, addBatchRecord, updateBatchRecord } from '@/api/cigarette/batchRecord'
import { pageBatch,listBatch, delBatch } from '@/api/cigarette/batch'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        batchName: null,

        batchId: null,

        status: 0,

        activationTime: null,

        activationTimeStamp: null,

        activationAddress: null,

        lng: null,

        lat: null,

        deviceId: null,
        group:1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        batchName: [
          { required: true, message: '批次名称不能为空', trigger: 'blur' }
        ]
      },
      batchListData:[]
    }
  },
  filters: {
  },
  created () {
    this.getBatchDataList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getBatchDataList() {
      listBatch().then(res => {
        this.batchListData = res.data;
      })
    },
    changeBatchId(e) {
      console.log(e)
      let batchName = this.batchListData.filter(i =>i.id == e);
      if(batchName.length > 0) {
        this.form.batchName = batchName[0].name;
        this.form.brandId = batchName[0].brandId;
        this.form.brandProduct = batchName[0].brandProduct;
        this.form.skuId = batchName[0].skuId;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        batchName: null,
        batchId: null,
        status: 0,
        activationTime: null,
        activationTimeStamp: null,
        activationAddress: null,
        lng: null,
        lat: null,
        deviceId: null,
        group:1
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBatchRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBatchRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBatchRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
